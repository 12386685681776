<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="主卡卡号" prop="mainCardNo" v-if="form.mainCardNo">
        <a-select
          show-search
          placeholder="请输入主卡卡号"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleMainCardSearch"
          @change="handleMainCardChange"
          :loading="loading"
          v-model="form.mainCardNo"
          :disabled="isOnlyView"
        >
          <a-select-option v-for="(d, index) in mainCardNoArray" :key="index">{{ d.cardNo }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="分卡卡号" prop="cardNo" v-if="form.cardNo">
        <a-select
          show-search
          placeholder="请输入分卡卡号"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleCardSearch"
          @change="handleCardChange"
          :loading="loading"
          v-model="form.cardNo"
          :disabled="isOnlyView"
        >
          <a-select-option v-for="(d, index) in cardNoArray" :key="index">{{ d.cardNo }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="车辆编号">
        <a-input v-model="form.vehicleNo" disabled placeholder="车辆编号" />
      </a-form-model-item>
      <a-form-model-item label="车牌号">
        <a-input v-model="form.licensePlateNum" disabled placeholder="车牌号" />
      </a-form-model-item>
      <a-form-model-item label="当前主卡总余额" prop="balanceMainCard">
        <a-input v-model="form.balanceMainCard" disabled placeholder="请输入当前主卡总余额" />
      </a-form-model-item>
      <a-form-model-item label="当前分卡总余额" prop="balanceBranchCard">
        <a-input v-model="form.balanceBranchCard" disabled placeholder="请输入当前分卡总余额" />
      </a-form-model-item>
      <a-form-model-item label="充值发起人" prop="createBy">
        <a-input v-model="form.createBy" disabled placeholder="请输入划拨金额" />
      </a-form-model-item>
      <a-form-model-item label="发起时间" prop="createTime">
        <a-input v-model="form.createTime" disabled placeholder="请输入当前分卡总余额" />
      </a-form-model-item>
      <a-form-model-item label="加油金额" prop="amount">
        <a-input v-model="form.amount" :disabled="isOnlyView" placeholder="加油金额" />
      </a-form-model-item>
      <a-form-model-item label="备注说明" prop="comment">
        <a-textarea v-model="form.comment" :disabled="isOnlyView" placeholder="请输入备注说明" />
      </a-form-model-item>
      <div class="bottom-control" v-if="!isOnlyView">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getOilAccountRecord, addOilAccountRecord, updateOilAccountRecord } from '@/api/iot/oilAccountRecord'
import { scorecardList, mainCardList } from '@/api/iot/oilCard'
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    this.handleMainCardSearch = debounce(this.handleMainCardSearch, 500)
    return {
      loading: false,
      isOnlyView: false,
      formTitle: '',
      // 表单参数
      form: {
        cardNo: null,
        accountType: 'transfer',
        amount: null,
        amountTransfer: null,
        balanceMainCard: null,
        balanceBranchCard: null,
        inchargeBy: null,
        comment: null,
        approvalStatus: null,
        createTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        cardNo: [{ required: true, message: '分卡卡号不能为空', trigger: 'blur' }],
        mainCardNo: [{ required: true, message: '主卡卡号不能为空', trigger: 'blur' }],
        amountTransfer: [{ required: true, message: '划拨金额', trigger: 'change' }]
      },
      cardNoArray: [],
      mainCardNoArray: []
    }
  },
  filters: {},
  created() {},
  computed: {
    ...mapState({
      userInfo: state => state.user.info
    })
  },
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.isOnlyView = false
      this.form = {
        cardNo: null,
        accountType: 'transfer',
        amount: null,
        amountTransfer: null,
        balanceMainCard: null,
        balanceBranchCard: null,
        inchargeBy: null,
        comment: null,
        approvalStatus: null,
        createTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(title, record) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = title
      this.initForm(record)
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getOilAccountRecord(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOilAccountRecord(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addOilAccountRecord(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    initForm(record) {
      console.log('record', record)
      if (record) {
        this.isOnlyView = true
        Object.assign(this.form, record)
        if (record.vehicleNo || record.licensePlateNum) {
          this.form.bindCard = (record.vehicleNo || '-') + '|' + (record.licensePlateNum || '-')
        } else {
          this.form.bindCard = '未绑定车辆'
        }
        return
      }
      this.form.createBy = this.userInfo.userName
      this.form.inchargeBy = this.userInfo.userId
      const d = new Date()
      const Y = d.getFullYear()
      const M = d.getMonth() + 1
      const D = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const s = d.getSeconds()
      const time = Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
      this.form.createTime = time
      console.log('this.form', this.form)
      this.$forceUpdate()
    },
    getScorecardList(value) {
      console.log('handleMainCardSearch', value)
      const queryParam = {
        mainCardNo: this.form.mainCardNo
      }
      this.loading = true
      scorecardList(queryParam).then(response => {
        this.scorecardList = response
        this.loading = false
      })
    },
    handleMainCardSearch(value) {
      console.log('handleMainCardSearch', value)
      const queryParam = {
        mainCardNo: value
      }
      this.loading = true
      mainCardList(queryParam).then(response => {
        this.mainCardNoArray = response
        this.loading = false
      })
    },
    handleMainCardChange(value) {
      console.log('handleMainCardChange', value)
      this.form.mainCardNo = this.mainCardNoArray[value].cardNo
      const tmpArr = this.mainCardNoArray.filter(res => res.cardNo === this.form.mainCardNo)
      console.log('tmpArr', tmpArr)
      if (tmpArr.length > 0) {
        this.form.balanceMainCard = tmpArr[0].balanceTotal
        this.form.balanceAvailable = tmpArr[0].balanceAvailable
      }
      console.log('this.form2', this.form)
      this.getScorecardList()
    },
    handleCardSearch(value) {
      console.log('handleMainCardSearch', value)
      this.cardNoArray = []
      for (const item of this.scorecardList) {
        if (item.cardNo.indexOf(value) > -1) {
          this.cardNoArray.push(item)
        }
      }
    },
    handleCardChange(value) {
      console.log('handleMainCardChange', value)
      this.form.cardNo = this.cardNoArray[value].cardNo
      const tmpArr = this.cardNoArray.filter(res => res.cardNo === this.form.cardNo)
      console.log('tmpArr', tmpArr)
      if (tmpArr.length > 0) {
        this.form.balanceBranchCard = tmpArr[0].balanceAvailable
        if (tmpArr[0].vehicleNo || tmpArr[0].licensePlateNum) {
          this.form.bindCard = (tmpArr[0].vehicleNo || '-') + '|' + (tmpArr[0].licensePlateNum || '-')
        } else {
          this.form.bindCard = '未绑定车辆'
        }
      }
      this.getScorecardList()
      console.log('this.form2', this.form)
    }
  }
}
</script>
